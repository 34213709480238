import React from 'react'
import { useTranslation } from 'react-i18next'
import Button, { type ButtonProps } from '~/components/Button'
import DownloadAppClient from '~/components/DownloadAppClient'
import Link from '~/components/Link'
import LinkButton from '~/components/LinkButton'
import { ROUTES } from '~/constants/routes'
import { useLanguageSwitcher } from '~/hooks/useLanguageSwitcher'

export type MenuFooterProps = {
  onClose: () => void
}

const MenuFooter = ({ onClose }: MenuFooterProps) => {
  const { t } = useTranslation()
  const languages = useLanguageSwitcher()

  return (
    <div className="flex items-center justify-between gap-y-8 py-4 max-sm:flex-col sm:container-gutters-x">
      <div className="flex gap-4 max-sm:w-full max-sm:flex-col max-sm:container-gutters-x">
        <DownloadAppClient
          render={({ appUrl, openModal }) => {
            const buttonProps = {
              children: t('common:downloadApp'),
              rounded: 'full',
              size: 'md',
              color: 'secondary',
              testId: 'downloadApp'
            } satisfies ButtonProps

            return !appUrl ? (
              <Button
                {...buttonProps}
                onClick={(event) => {
                  event.preventDefault()
                  onClose()
                  openModal()
                }}
              />
            ) : (
              <LinkButton to={appUrl} onClick={onClose} {...buttonProps} />
            )
          }}
        />
        <LinkButton
          to={ROUTES.contactUs}
          color="primary"
          variant="contained"
          className="max-sm:w-full"
          rounded="full"
          onClick={onClose}
          size="md"
          testId="contactUs"
        >
          {t('common:contactUs')}
        </LinkButton>
      </div>
      <div className="max-sm:-order-1 max-sm:w-full">
        <div className="flex h-[56px] border-y-1 border-white/10 sm:h-auto sm:gap-x-3 sm:border-0">
          {languages.map((language, index) => {
            return (
              <React.Fragment key={language.locale}>
                <Link
                  key={language.locale}
                  to={language.to}
                  testId={`language${language.locale.toUpperCase()}`}
                  translate="no"
                  reloadDocument
                  aria-current={language.isActive}
                  className="flex flex-grow items-center justify-center gap-x-2 text-body1 text-white/50 hover:text-white aria-[current=true]:text-white"
                >
                  {language.locale.toUpperCase()}
                </Link>
                {index < languages.length - 1 ? (
                  <span className="block h-full w-[1px] bg-white/10 sm:hidden" />
                ) : null}
              </React.Fragment>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default MenuFooter
