import React from 'react'
import { useTranslation } from 'react-i18next'
import Burger from '~/components/Burger'
import Logo from '~/components/icons/Logo'
import Link from '~/components/Link'
import { NavbarGrid } from '~/components/Nav/Navbar.utils'

export type MenuHeaderProps = {
  onClose: () => void
}

const MenuHeader = ({ onClose }: MenuHeaderProps) => {
  const { t } = useTranslation('common')

  return (
    <NavbarGrid>
      <NavbarGrid.NavbarGridItem align="start">
        <Link
          prefetch="none"
          to="/"
          aria-label={t('common:zoiHomePage')}
          testId="headerHome"
        >
          <Logo />
        </Link>
      </NavbarGrid.NavbarGridItem>
      <NavbarGrid.NavbarGridItem align="center" />
      <NavbarGrid.NavbarGridItem align="end">
        <Burger isOpened onClick={onClose} className="text-white" />
      </NavbarGrid.NavbarGridItem>
    </NavbarGrid>
  )
}

export default MenuHeader
